import React from 'react'

import { MotionText } from '../components/common/Text'
import Img from 'gatsby-image'
import Social from '../components/Social'
import SEO from '../components/Seo'

import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import useWindowSize from '../hooks/useWindowSize'
import breakpoints from '../constants/breakpoints'

const container = {
  initial: {},
  exit: {},
  animate: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.05,
    },
  },
}

const fade = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

function About({ data }) {
  const {
    title,
    bio,
    image: { file, fluid },
    socialLinks,
  } = data?.contentfulAbout
  const siteTitle = data?.contentfulSite?.metaTitle
  const description = data?.contentfulSite.metaDescription.childMarkdownRemark.rawMarkdownBody
  const shareImage = data?.contentfulSite?.sharingImage?.fixed?.src

  const size = file?.details?.image || {}

  const { width } = useWindowSize()

  const img = (
    <Img
      fluid={fluid}
      imgStyle={{
        objectFit: 'contain',
        objectPosition: width > 1520 || width <= breakpoints.md ? 'center center' : 'center right',
      }}
      alt="About"
      {...size}
    />
  )

  return (
    <>
      <SEO description={description} siteTitle={siteTitle} shareImage={shareImage} title={title} />

      <Container variants={container} initial="initial" animate="animate">
        <Top variants={fade}>{img}</Top>

        <Right>
          {bio.map(({ text }, i) => (
            <BodyText condensed lineHeight={1.4} key={`txt${i}`} variants={fade}>
              {documentToReactComponents(text.json)}
            </BodyText>
          ))}
          {!!socialLinks && socialLinks.length && (
            <motion.div variants={fade}>
              <Social socialLinks={socialLinks} />
            </motion.div>
          )}
        </Right>

        <Left variants={fade}>{img}</Left>
      </Container>
    </>
  )
}

export default About

const Container = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    ${theme.media.min('md')} {
      flex-direction: row;
    }
  `}
`

const BodyText = styled(MotionText)`
  ${({ theme }) => css`
    position: relative;

    margin-bottom: ${rem(40)};

    p:not(:last-child) {
      margin-bottom: ${rem(15)};
    }

    i {
      display: inline-flex;
      transform: translateY(-1px);
      font-style: normal;
    }

    &:not(:first-child):not(:last-child):after {
      content: ' ';
      position: absolute;

      top: 0;
      left: 0;

      transform: translateY(${rem(-20)});

      width: ${rem(24)};
      height: ${rem(1)};
      background-color: ${theme.color.secondary};
    }
  `};
`

const Right = styled(motion.div)`
  ${({ theme }) => css`
    width: 100vw;
    max-width: ${rem(900)};

    padding: ${rem(20)} ${rem(20)};

    ${theme.media.min('sm')} {
      padding: ${rem(40)} ${rem(40)};
    }

    ${theme.media.min('md')} {
      width: 60vw;
      padding: ${rem(40)};
      padding-right: 0;
    }

    ${theme.media.min('lg')} {
      padding: ${rem(80)};
    }
  `};
`

const imageContainer = ({ theme }) => css`
  position: relative;
  display: none;
  justify-content: center;
  width: 100vw;
  height: 100%;

  padding-top: ${rem(20)};

  ${theme.media.min('md')} {
    display: flex;

    width: 40vw;
    padding: ${rem(40)} 0;
    padding-right: ${rem(40)};
    order: 2;
  }

  ${theme.media.min('lg')} {
    padding: ${rem(80)} 0;
    padding-right: ${rem(80)};
  }

  img,
  picture {
    object-position: center right;
  }

  > * {
    width: 100%;
    height: 100%;
    max-height: ${rem(600)};
  }
`

const Left = styled(motion.div)`
  ${({ theme }) => css`
    ${imageContainer}
  `};
`

const Top = styled(motion.div)`
  ${({ theme }) => css`
    ${imageContainer};
    display: flex;

    ${theme.media.min('md')} {
      display: none;
    }
  `};
`

export const pageQuery = graphql`
  query AboutPage {
    contentfulSite {
      backToIndexLabel
      links {
        title
        page {
          slug
        }
        externalUrl
      }
      siteTitle {
        json
      }
      siteTitleShort {
        json
      }
      metaDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      metaTags
      metaTitle
      sharingImage {
        file {
          url
        }
        fixed(width: 1200, height: 630, quality: 80) {
          height
          src
          width
        }
      }
    }
    contentfulAbout {
      title
      slug
      bio {
        text {
          json
        }
      }
      image {
        file {
          details {
            image {
              height
              width
            }
          }
        }
        fluid(maxWidth: 2000, maxHeight: 2000, quality: 80) {
          srcWebp
          srcSetWebp
          src
          sizes
          srcSet
          aspectRatio
        }
      }
      socialLinks {
        email
        url
        title
        svgImage {
          file {
            url
          }
        }
        svgImageLight {
          file {
            url
          }
        }
      }
    }
  }
`
